<template>
  <OverviewTab class="pa-4" location="allProjectsAlerts"/>
</template>

<script>
  import OverviewTab from '@/pages/fullViewReport/components/overview/OverviewTab.vue';

  export default {
    components: {
      OverviewTab
    },
    name: 'AlertManagementAllProjects'
  };
</script>